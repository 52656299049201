export enum EmailTypes {
    // feedbacks
    customerFeedbackRequest = "customerFeedbackRequest",
    feedbackCollection = "feedback-collection",

    // agents
    travelAgentApproval = "travelAgentApproval",
    travelAgentLoginReminder = "travelAgentLoginReminder",
    travelAgentFirstBooking = "travelAgentFirstBooking",
    travelAgentFirstLogin = "travelAgentFirstLogin",
    travelAgentBookingReminder = "travelAgentBookingReminder",
    TravelAgentForgottenAccount = "TravelAgentForgottenAccount",
    travelAgentMistakeEmail = "travelAgentMistakeEmail",
    travelAgentRegistration = "travelAgentRegistration",
    travelAgentResetPassword = "travelAgentResetPassword",
    travelAgentFeedbackRequest = "travelAgentFeedbackRequest",
    commissionReadyForPayment = "commissionReadyForPayment",
    commissionPostponed = "commissionPostponed",

    // bookings
    ordersReminder = "ordersReminder",
    driverChanged = "driverChanged",
    orderConfirmation = "orderConfirmation",
    vehicleChanged = "vehicleChanged",
    orderAccepted = "orderAccepted",
    orderCancellation = "orderCancellation",
    orderDeclined = "orderDeclined",
    orderPickupAddressAirport = "orderPickupAddressAirport",
    poolOrderConfirmed = "poolOrderConfirmed",
    unpaidPreauthorizedPaymentOrder = "unpaidPreauthorizedPaymentOrder",
    higherCategoryCarNotification = "higherCategoryCarNotification",
    orderPickupAddressPort = "orderPickupAddressPort",
    orderPickupAddressMissing = "orderPickupAddressMissing",
    orderPickupAddressTrain = "orderPickupAddressTrain",
    orderPickupAddressMeetingPosition = "orderPickupAddressMeetingPosition",
    orderChanges = "orderChanges",
    tripTrackingEmail = "tripTrackingEmail",
    orderOwnChildSeat = "orderOwnChildSeat",
    customTripNotAvailable = "customTripNotAvailable",

    // customers
    userRegistrationVerification = "userRegistrationVerification",
    customerVerification = "customerVerification",
    abandonedCart = "abandonedCart",
    poolAbandonedCart = "poolAbandonedCart",
    returnTripUpsellEmail = "returnTripUpsellEmail",
    referralInitial = "referralInitial",
    referralNotification = "referralNotification",
    referralReferee = "referralReferee",
    failedPreauthorizedPayment = "failedPreauthorizedPayment",

    // drivers
    firstRideFeedbackRequest = "firstRideFeedbackRequest",
    sendUpcomingTripsRemindersForDriverEmails = "sendUpcomingTripsRemindersForDriverEmails",
    sendUpcomingTripChangeForDriver = "sendUpcomingTripChangeForDriver",
    sendAssignationsAcceptedEmails = "sendAssignationsAcceptedEmails",
    sendAssignationsCancelledEmails = "sendAssignationsCancelledEmails",
    sendAssignationsDeclinedAutomaticallyEmails = "sendAssignationsDeclinedAutomaticallyEmails",
    sendAssignationsDeclinedByDriverEmails = "sendAssignationsDeclinedByDriverEmails",
    sendAssignationsPendingEmails = "sendAssignationsPendingEmails",
    ridePending = "ridePending",
    rideAccepted = "rideAccepted",
    rideChanged = "rideChanged",
    rideDeclinedByDriver = "rideDeclinedByDriver",
    rideDeclinedAutomatically = "rideDeclinedAutomatically",
    signedCooperationAgreementDynamicForm = "signedCooperationAgreementDynamicForm",
    stripeAccountOnboardingLinkEmail = "stripeAccountOnboardingLinkEmail",
    documentDeclined = "documentDeclined",
    profilePhotoDeclined = "profilePhotoDeclined",
    driverDeactivated = "driverDeactivated",
    firstRideAfterThreeMonths = "firstRideAfterThreeMonths",
    rideCancelled = "rideCancelled",
    activateYourStripeWallet = "activateYourStripeWallet",
    payoutFailedDueToInactiveStripeWallet = "payoutFailedDueToInactiveStripeWallet",
    personalComboAssignationOffer = "personalComboAssignationOffer",

    // management notifications
    paymentUnsuccessful = "paymentUnsuccessful",
    cautionDoubleBookingsNotification = "cautionDoubleBookingsNotification",
    urgentDoubleBookingsNotification = "urgentDoubleBookingsNotification",
    ordersCustomNotification = "ordersCustomNotification",
    orderMessageToManager = "orderMessageToManager",
    confirmationEmailFailed = "confirmationEmailFailed",
    unconfirmedOrderWithAcceptedAssignationNotification = "unconfirmedOrderWithAcceptedAssignationNotification",

    // users
    passwordReset = "passwordReset",
    passwordResetInstructions = "passwordResetInstructions",
    magicLinkVerification = "magicLinkVerification",

    // campaigns
    visa50KCampaignDiscountCode = "visa50KCampaignDiscountCode",
}
